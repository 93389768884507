
import { defineComponent, reactive, ref, Ref, onMounted, inject } from "vue";
import { useI18n } from "vue-i18n";
import {
  ExclamationCircleFilled,
  MoreOutlined,
  WarningOutlined,
} from "@ant-design/icons-vue";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import ChooseVisit from "../components/ChooseVisit.vue";
import {
  Visit,
  ResponseList,
  VisitStatus,
  FinalVisitStatus,
  VisitExt,
  VisitsFilterData,
  ChooseVisitModal,
} from "../../types";
import { TableState } from "ant-design-vue/lib/table/interface";
import { SorterType } from "../../types/vue-antd";
import { Badge } from "ant-design-vue";
import VisitsFilters from "../components/VisitsFilters.vue";
import { visitTypesIconMap } from "../utils/const";
import { useRouter, useRoute } from "vue-router";
import { CheckUserAgent } from "@hd2/common/src/utils";
import moment, { Moment } from "moment";
import { AxiosStatic } from "axios";
import { useAurero } from "../composable/useAurero";

interface Table {
  columns: TableState["columns"];
  sorter: SorterType;
  data: Array<VisitExt>;
  pagination: Omit<TableState["pagination"], "current,total,pageSize"> & {
    current: number;
    total: number;
    pageSize: number;
  };
  loading: boolean;
}

export const HistoryComponent = defineComponent({
  components: {
    ExclamationCircleFilled,
    MoreOutlined,
    VisitsFilters,
    ChooseVisit,
    WarningOutlined,
    Badge,
  },
  setup() {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const { showErrors } = useShowErrors();
    const router = useRouter();
    const route = useRoute();
    const isSupported =
      !CheckUserAgent.isSafari() && !CheckUserAgent.isOperaMobile();
    const { getVisitUrl } = useAurero();

    let filters: VisitsFilterData = {
      date: null,
      pesel: "",
      visitTypes: [],
      statuses: ["FINISHED", "CANCELLED_BY_DOCTOR", "FINISHED_NO_CONTACT"],
    };
    const page: number = route.query.page ? Number(route.query.page) : 1;
    const pageSize: number = route.query.pageSize
      ? Number(route.query.pageSize)
      : 10;

    const defaultStatuses = [
      "FINISHED",
      "CANCELLED_BY_DOCTOR",
      "FINISHED_NO_CONTACT",
    ];

    const visitsFilters: Ref<typeof VisitsFilters> = ref(VisitsFilters);

    const visitFinalSatusesLoading: Ref<boolean> = ref(false);
    const finalVisitStatuses: Ref<Array<VisitStatus>> = ref([]);

    const table: Table = reactive({
      data: [],
      columns: [
        {
          title: t("HISTORY.TABLE.APPOINTMENT_DATE_TIME"),
          dataIndex: "from",
          sorter: false,
          slots: { customRender: "appointmentDateTime" },
        },
        {
          title: t("HISTORY.TABLE.PATIENT_DATA"),
          dataIndex: "patient",
          sorter: false,
          slots: { customRender: "patient" },
        },
        {
          title: t("VISITS.TABLE.PREFERRED_VISIT_LANGUAGE"),
          dataIndex: "preferredVisitLanguage",
          sorter: false,
          slots: { customRender: "preferredVisitLanguage" },
        },
        {
          title: t("HISTORY.TABLE.CONTRACTOR"),
          dataIndex: "contractor",
          sorter: false,
          slots: { customRender: "contractor" },
        },
        {
          title: t("VISITS.TABLE.NFZ"),
          sorter: false,
          slots: { customRender: "nfz" },
        },
        {
          title: t("HISTORY.TABLE.VISIT_DATA"),
          dataIndex: "details",
          sorter: false,
          slots: { customRender: "details" },
        },
        {
          title: t("HISTORY.TABLE.PAYMENT"),
          dataIndex: "payment",
          sorter: false,
          slots: { customRender: "payment" },
        },
        {
          title: t("HISTORY.TABLE.STATUS"),
          dataIndex: "status",
          sorter: false,
          slots: { customRender: "status" },
        },
        {
          dataIndex: "showDetails",
          sorter: false,
          slots: { customRender: "showDetails" },
        },
      ],
      sorter: {},
      loading: true,
      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "25", "50"],
        buildOptionText: ({ value }: { value: string }) => {
          return value;
        },
        showTotal: (total: number, range: Array<number>) =>
          t("PAGINATION", {
            total,
            rangeFrom: range[0],
            rangeTo: range[1],
          }),
        current: page,
        pageSize: pageSize,
        total: 0,
      },
    });

    const chooseVisitModal: ChooseVisitModal = reactive({
      visible: false,
      data: undefined,
      paymentType: "",
      redirectUrl: "",
    });

    const goToVisit = async (record: VisitExt) => {
      if (
        record.patients.length > 1 ||
        record.details.type === "HOUSE" ||
        (record.patients[0].symptoms
          ? record.patients[0].symptoms.length > 0
          : false) ||
        (record.countryOfResidence
          ? record.countryOfResidence != "POL"
          : false) ||
        (record.countryOfResidence
          ? record.countryOfResidence.length === 0
          : false)
      ) {
        chooseVisitModal.data = record;
        chooseVisitModal.visible = true;
        chooseVisitModal.paymentType = record.payment.type;
        chooseVisitModal.redirectUrl = `?redirectUrl=${route.path}&page=${table.pagination.current}&pageSize=${table.pagination.pageSize}&choosedVisitId=${record.id}`;
      } else {
        if (isSupported) {
          router.push(
            `/visit/${record.patients[0].aureroVisitId}?redirectUrl=${route.path}&page=${table.pagination.current}&pageSize=${table.pagination.pageSize}&choosedVisitId=${record.id}`
          );
        } else {
          const windowReference = window.open("#");
          await getVisitUrl(record.patients[0].aureroVisitId.toString()).then(
            (url: string) => {
              windowReference?.location.assign(url);
            }
          );
        }
      }
    };

    const getVisits = async (
      pagination: TableState["pagination"],
      filters: VisitsFilterData
    ) => {
      filters.statuses =
        filters.statuses.length > 0
          ? filters.statuses
          : (defaultStatuses as Array<VisitStatus>);
      table.loading = true;
      const link = `v1/visits?page=${
        pagination?.current ? pagination?.current - 1 : 0
      }&size=${pagination?.pageSize}${
        filters.date ? `&date=${filters.date?.format("YYYY-MM-DD")}` : ""
      }&pesel=${filters.pesel}&visitType=${filters.visitTypes.join(
        ","
      )}&statuses=${filters.statuses.toString()}&sort=date,DESC`;

      try {
        const visitsRes = await http
          .get(link)
          .then((res): ResponseList<Visit> => res.data);
        table.data = visitsRes.content.map((visit: Visit) => {
          return {
            ...visit,
            tmpStatus: visit.status,
            isChangeStatusDisabled: true,
            details: {
              ...visit.details,
            },
          };
        });
        table.pagination = {
          current: visitsRes.pageable.pageNumber + 1,
          total: visitsRes.totalElements,
          pageSize: visitsRes.size,
        };
      } catch (err: any) {
        showErrors(err.response?.data);
      } finally {
        table.loading = false;
        if (route.query.choosedVisitId) {
          const record: VisitExt | undefined = table.data.find(
            (elem) => elem.id === Number(route.query.choosedVisitId)
          );
          if (
            record &&
            record.details.type == "HOUSE" &&
            record.patients.length > 1
          ) {
            goToVisit(record);
          }
        }
      }
    };

    const getFinalVisitStatuses = async () => {
      try {
        finalVisitStatuses.value = await http
          .get("v1/visits/final-statuses")
          .then((res): Array<FinalVisitStatus> => res.data);
      } catch (err: any) {
        showErrors(err.response?.data);
      }
    };

    onMounted(() => {
      getVisits(table.pagination, filters);
      getFinalVisitStatuses();
    });

    const showPaymentStatus = (status: string, type: string) => {
      if (
        type.includes("CONTRACT") ||
        type.includes("PAID") ||
        status === "ACCEPTED"
      ) {
        return t(`PAYMENT_TYPE.PAID`);
      } else if (type === "CASH") {
        return t(`PAYMENT_TYPE.CASH`);
      } else if (status === "REJECTED") {
        return t(`PAYMENT_TYPE.${type}`);
      }
    };

    const handleTableChange = async (pagination: TableState["pagination"]) => {
      router.replace(
        `${route.path}?page=${pagination?.current}&pageSize=${pagination?.pageSize}`
      );
      await getVisits(pagination, filters);
    };

    const dateRange = (current: Moment) => {
      return current && current >= moment().startOf("day");
    };

    const onVisitsFilterSubmit = (value: VisitsFilterData) => {
      filters = value;
      table.pagination.current = 1;
      getVisits(table.pagination, filters);
    };
    return {
      t,
      finalVisitStatuses,
      table,
      visitsFilters,
      visitFinalSatusesLoading,
      onVisitsFilterSubmit,
      handleTableChange,
      filters,
      showPaymentStatus,
      goToVisit,
      chooseVisitModal,
      page,
      pageSize,
      defaultStatuses,
      isSupported,
      visitTypesIconMap,
      dateRange,
    };
  },
});
export default HistoryComponent;
